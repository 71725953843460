import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { RequestOptions, Request, RequestMethod, Headers } from "@angular/http";
import { Http } from "@angular/http";
import "rxjs/add/operator/map";

import { firebase } from "@firebase/app";
import "@firebase/messaging";
import { environment } from "../../environments/environment";
import { utf8Encode } from "@angular/compiler/src/util";
import { Events, Platform } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import {S3imagesService} from './s3images.service';

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
@Injectable({
  providedIn: "root",
})
export class WeeklyService {
  FirstHeadingWeekly: string;
  LoginCardNo: string;
  LoginPassword: string;
  loginPassword:string;
  LoginHeader: string;
  CartCount: any;
  isGuest: string;
  Currency: string;
  FirebaseToken: string;
  films: Observable<any>;
  login: Observable<any>;
  isLoginGoBack: boolean = false;
  searchPlaceHolder: String = "What are you looking for ?";
  conditionApp: boolean = true;
  headerres: Observable<any>;

  // url = 'http://sha-phdpmnbbcn.dynamic-m.com/doitweb/doitcenter.asmx/'
  // url = 'http://localhost:50480/DoItCenter.asmx/'
  // url = 'http://192.168.1.229/doitweb/doitcenter.asmx/' //local site
  //url ='http://sha-phdpmnbbcn.dynamic-m.com/doitcenterwebsite/doitcenter.asmx/' //live till 20/10/21
   //url = "https://loyalty.doit.aw:8080/doitcenterwebsite/doitcenter.asmx/"; //original url for live site
  // url='https://dashboard.doit.aw:8081/doitcenterwebsite/doitcenter.asmx/'
  // url='https://adarshtated.com/dev/spacenter/api/'
  //  url ='http://192.168.1.71/doitwebsiteapi/doitcenter.asmx/'
  //priyanka has commented this
  // url= 'http://loyalty.doit.aw:8082/doitcenterwebsite/doitcenter.asmx/'
  // url= "http://173.199.122.118/DoitWebsiteapi/doitcenter.asmx/"
  // url=" http://sha-phdpmnbbcn.dynamic-m.com/doitweb/doitcenter.asmx/"
  url ="https://loyalty.doit.aw:8082/doitcenterwebsite/doitcenter.asmx/"// latest live api 
  // url="https://loyalty.doit.aw:8082/doitcenterwebsite/doitcenter.asmx/"//live URL last live`
  //  url ="http://win-server/DoitWebSiteAPI/Doitcenter.asmx/" //local server
      // url="http://win-server/DoitWebSiteAPI/doitcenter.asmx/"
  // url ="http://192.168.1.229/edocngg/TblUserInfo.asmx/"
  // url='http://sha-phdpmnbbcn.dynamic-m.com/doit_latest/doitcenter.asmx/'
  // url='http://192.168.1.229/doitwebsiteapi/doitcenter.asmx/'
  // url='http://192.168.1.229/checkwebservicedoit/doitcenter.asmx/'

  
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa('WEBAPI:SSjvDC9FijvuYLlEp6w4UzHb9VTOAjCA+DWeHTcocjw=')
    })
  };
  imageUrl = this.S3images.s3url;
  // imageUrl = "https://d2s3cc6x0yl03x.cloudfront.net/ProductImages/";
  noImageUrl ="https://d2s3cc6x0yl03x.cloudfront.net/ProductImages/no_image.jpg";
  imageFormat = ".jpg";

  // dynamicurl = "http://sha-phdpmnbbcn.dynamic-m.com:9048/APP/ODataV4/Company('Doit Center')/";
  dynamicurl= "https://general.doit.aw:9048/APP/ODataV4/Company('Doit Center')/";
  userInfoList: any;
  constructor(
    private http: HttpClient,
    public storage: Storage,
    public platform: Platform,
    private events: Events,
    private  S3images: S3imagesService,
  ) {
    if(this.LoginCardNo === '1' && this.loginPassword === '1' &&  this.LoginHeader === 'vugwI876543'){
      this.getGuestLogin();
      // console.log("bgfhg", this.LoginCardNo)
    }else{
      this.storage.get('CardNo').then((val) => {
        this.setStorageCardNo(val);
        // console.log( " apiService.setStorageCardNo(val)",val, "ghgg")
        this.storage.get('Password').then((val1) => {
          this.setStoragePassword(val1);
          // console.log( " apiService.setStoragePassword(val1)",val1)
          this.storage.get('AccountType').then((val) => {
            // console.log("AccountType", val)
          });
        });
      });
    }
   

    this.getHeaderRes();
  }

  mydata: MyDataType[];
  dryGroceryMain: any;
  dairyMain: any;
  Price:string ;
  top10WishListdata: any;
  top10WishListOrederdata: any;

  // getUsersForSubscription(
  //   groupname: string,
  //   CardNo: string,
  //   header: string,
  //   Password: string,
  //   pageno: string
  // ): Observable<MyDataType[]> {
  //   this.dryGroceryMain = this.http.get(
  //     this.url +
  //       "getSubGroup?group=" +
  //       groupname +
  //       "&cardno=" +
  //       CardNo +
  //       "&Header=" +
  //       header +
  //       "&Password=" +
  //       Password +
  //       "&Pageno=" +
  //       pageno
  //   );
  //   return this.dryGroceryMain;
  // }

  getCatDataWithObservable
  (
    CardNo: string,
    header: string,
    Password: string
  ): Observable<CatDataType[]> {
    this.films = this.http.get(
      this.url +
        "getGroupNew?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getTop10UserWishlistWithObservable(
    CardNo: string,
    header: string,
    Password: string
  ): Observable<{
    Product: Top10Data[]; data: Top10Data[] 
}> {
    this.top10WishListdata = this.http.get(
      this.url +
        "Top10UserWishlist?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );


    return this.top10WishListdata;
  }

  getTop10UserOrderedlistWithObservable(
    CardNo: string,
    header: string,
    Password: string
  ): Observable<{
    Product: Top10Data[]; data: Top10Data[] 
}> {
    this.top10WishListOrederdata = this.http.get(
      this.url +
        "Top10UserOrderedlist?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.top10WishListOrederdata;
  }

  getTop10UserProcessedOrderedlistWithObservable(
    CardNo: string,
    header: string,
    Password: string
  ): Observable<{
    Product: Top10Data[]; data: Top10Data[] 
}> {
    this.top10WishListOrederdata = this.http.get(
      this.url +
        "Top10UserProcessedOrderedlist?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.top10WishListOrederdata;
  }

  //  ===========================load basic data=========================================

  getHeaderRes() {
    this.headerres = this.getHeader();
    this.headerres.subscribe((data) => {
      if (data.status == "false") {
      } else if (data.status == "true") {
        this.setStorageHeader(data.header);
        this.doneClick();
        this.events.publish("user:loggedin");
      }
    });
  }

  doneClick() {
    let currency = "fls";
    this.setCurrency(currency);

    this.storage.get("loginId").then((val) => {
      let loginId = val;

      if (loginId == null) {
        this.getGuestLogin();
      } else {
        this.openHome();
      }
    });
  }

  getGuestLogin() {
    // this.storage.set('loginUsername', "");
    // this.storage.set('Header',"vugwI876543");
    this.storage.set("CardNo", "1");

    this.setIsGuest("true");

    this.setStorageCardNo("1");

    this.setCurrency("");
    this.openHome();
  }

  openHome() {
    // console.log("~service~~~StorageHeader~~" + this.getStorageHeader());
    // console.log("~service~~~StorageCardNo~~" + this.getStorageCardNo());
    // console.log("~service~~~StoragePassword~~" + this.getStoragePassword());

    // this.startMyThread().then(truthy => {
    //   this.router.navigate(['home'], { replaceUrl: true });
    // });
  }

  
  // ====================================================All api with microsoft dynamic server==================================================================
  getProductListDynamic(skip, next, subgroupcode) {
    this.films = this.http.get(this.dynamicurl + "AppItemList?$filter=LSC_Retail_Product_Code eq '" +
      subgroupcode + "' &$skip=" + skip + "&$top=" + next, this.httpOptions);
    return this.films;
  }

  getProductImage(imagecode) {
    this.films = this.http.get(this.dynamicurl + "AppRetailImage?$filter=Code eq '" +
      imagecode + "' ", this.httpOptions);
    return this.films;
  }

  getMemberDetail(cardno) {
    this.films = this.http.get(this.dynamicurl + "AppMemberContact?$filter=Account_No eq '" +
      cardno + "' ", this.httpOptions);
    return this.films;
  }

  getSearchDynamic(skip, next, searchkey) {
    this.films = this.http.get(this.dynamicurl + "AppItemList?$filter="+
    searchkey+ " and"+ " LSC_Exclude_from_Replenishment eq false" +"&$skip=" + skip + "&$top=" + next, this.httpOptions);
    return this.films;
  }

  getGroupDynamic(groupHeading) {
    this.films = this.http.get(this.dynamicurl + "AppProductGroup?$filter=Item_Category_Code eq '" + groupHeading + "'", this.httpOptions);
    // this.films = this.http.get(this.dynamicurl + "AppCategory?$filter=LSC_Division_Code eq '"+groupHeading+"'", this.httpOptions);
    return this.films;
  }
  getGroupCategoryDynamic(groupHeading) {
    // this.films = this.http.get(this.dynamicurl + "AppProductGroup?$filter=Item_Category_Code eq '" + groupHeading + "'", this.httpOptions);
    this.films = this.http.get(this.dynamicurl + "AppCategory?$filter=LSC_Division_Code eq '"+groupHeading+"'", this.httpOptions);
    return this.films;
  }

  getSearchpage(skip, next, searchkey) {
    this.films = this.http.get(this.dynamicurl + "AppItemList?$filter="+
    searchkey+ " and"+ " LSC_Exclude_from_Replenishment eq false" +"&$skip=" + skip + "&$top=" + next, this.httpOptions);
    return this.films;
  }
  getSubGroupDynamic(skip, next, categoryid) {
    this.films = this.http.get(this.dynamicurl + "AppProductGroup?$filter=Item_Category_Code eq '" + categoryid + "'"+ " and"+ " LSC_Exclude_from_Replenishment eq false", this.httpOptions);

    return this.films;
  }

  
  // ====================================================/All api with microsoft dynamic server==================================================================
 

  // ================================load basic data======================================

  // ===================================================All api with security header===================================================================

  getHeader() {
    this.films = this.http.get(this.url + "getHeader");
    return this.films;
  }

  getLogin(
    CardNo: string,
    pass: string,
    header: string,
    firebasetoken: string
  ) {
    this.films = this.http.get(
      this.url +
        "Login?Cardno=" +
        CardNo +
        "&Password=" +
        pass +
        "&Header=" +
        header +
        "&FirebaseToken=" +
        firebasetoken
    );
    return this.films;
  }

  fetchWeeklySpecialData(
    CardNo: string,
    header: string,
    category: string,
    Password: string,
    pageno: string
  ) {
    category = encodeURIComponent(category);
    // console.log("~~~category~~~" + category);
    this.films = this.http.get(
      this.url +
        "FetchWeeklySpecial?category=" +
        category +
        "&cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&Pageno=" +
        pageno
    );
    return this.films;
  }

  getUserDetail(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "UserDetail?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getGroceriesGroup(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "getGroupNew?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  // getGroceriesSubGroup(groupname: string, CardNo: string, header: string,Password: string ,pageno :string) : Observable<MyDataType[]>{

  getGroceriesSubGroup(
    groupname: string,
    CardNo: string,
    header: string,
    Password: string,
    pageno: string
  ) {
    this.films = this.http.get(
      this.url +
        "getSubGroup?group=" +
        groupname +
        "&cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&Pageno=" +
        pageno
    );
    return this.films;
  }
  getSubGroup(groupname){
    this.films = this.http.get(this.dynamicurl + "AppProductGroup?$filter=Item_Category_Code eq '" + groupname + "'", this.httpOptions);

  }
  getProducts(
    subgrp: string,
    CardNo: string,
    header: string,
    Password: string,
    pageno: string
  ) {
    this.films = this.http.get(
      this.url +
        "getSubGroupProduct?subgroup=" +
        subgrp +
        "&cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&Pageno=" +
        pageno
    );
    return this.films;
  }

  getGenratePassword(CardNo: string) {
    this.films = this.http.get(this.url + "GeneratePassword?Cardno=" + CardNo);
    return this.films;
  }

  getSearch(
    CardNo: string,
    header: string,
    searchkey: string,
    Password: string
  ) {
    searchkey = encodeURIComponent(searchkey);

    this.films = this.http.get(
      this.url +
        "getSearch?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&searchkey=" +
        searchkey +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getBarcodeSearch(
    CardNo: string,
    header: string,
    searchkey: string,
    Password: string
  ) {
    searchkey = encodeURIComponent(searchkey);
    this.films = this.http.get(
      this.url +
        "getBarcodeSearch?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&searchkey=" +
        searchkey +
        "&Password=" +
        Password
    );
    return this.films;
  }

  matchdiscount(offerno) {
    //this.films = this.http.get(this.dynamicurl + "AppPeriodicDiscountLine?$filter="+itemno, this.httpOptions);
    this.films = this.http.get(this.dynamicurl + "AppPeriodicDiscount?$filter=No eq '"+offerno+"'", this.httpOptions);
      console.log("UUUUURRRRRLLLLLLLLL", this.dynamicurl + "AppPeriodicDiscount?$filter=No eq '"+offerno+"'", this.httpOptions)
    return this.films;
  }

  getmessureunit(itemno) {
    this.films = this.http.get(this.dynamicurl + "AppPeriodicDiscountLine?$filter="+itemno, this.httpOptions);
   // console.log("UUUUURRRRRLLLLLLLLL", this.dynamicurl + "AppBarcode?$filter="+searchkey+"&$skip=" + skip + "&$top=" + next, this.httpOptions)
    return this.films;
  }
  multibuycheck(offerno){
    this.films = this.http.get(this.dynamicurl + "AppMultibuyDicountLine?$filter=contains(Group,'"+offerno+"')", this.httpOptions);
    return this.films;
  }
  unitquantity(itemno) {
    //this.films = this.http.get(this.dynamicurl + "AppPeriodicDiscountLine?$filter="+itemno, this.httpOptions);
    this.films = this.http.get(this.dynamicurl + "AppItemUnitofMeasure?$filter=Item_No eq '"+itemno+"'", this.httpOptions);
      console.log("UUUUURRRRRLLLLLLLLL", this.dynamicurl + "AppItemUnitofMeasure?$filter=Item_No eq '"+itemno+"'", this.httpOptions)
    return this.films;
  }
  addItemInCart(
    CardNo: string,
    header: string,
    Code: string,
    cardWishType: string,
    qty: number,
    packQuantity: string,
    Password: string,
    Price:string ,
    sellunit:any,
    finalDisPe:any
  ) {
    this.films = this.http.get(
      this.url +
        "addItemInCartDynamic?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Code=" +
        Code +
        "&CartwishType=" +
        cardWishType +
        "&quantity=" +
        qty +
        "&packQuantity=" +
        packQuantity +
        "&Password=" +
        Password +
        "&Price=" +
        Price + 
        "&sellunit=" +
        sellunit +
        "&DiscountAmt=" +
        finalDisPe
    );
    // console.log(
    //   "~~by additem in card~~~" +
    //   this.url +
    //   "addItemInCart?Cardno=" +
    //   CardNo +
    //   "&Header=" +
    //   header +
    //   "&internalkey=" +
    //   internalkey +
    //   "&CartwishType=" +
    //   cardWishType +
    //   "&quantity=" +
    //   qty +
    //   "&packQuantity=" +
    //   packQuantity +
    //   "&Password=" +
    //   Password +
    //   "&Price=" +
    //   Price
    // );
    return this.films;
  }
  GuestOrder(jsonstring:any) {
    // CardNo= encodeURIComponent(CardNo);
    this.films = this.http.get(this.url + "GuestOrder?jsonstring=" + jsonstring );
    return this.films;
  }
  GetofferandpriceDynamic(CardNo: string, header: string, Password: string, productkey: string) {
    this.films = this.http.get(this.url + "GetSingleProductwithofferandpriceDynamic?cardno=" + CardNo + "&Header=" +
      header + "&Password=" + Password + "&productkey=" + productkey);
    return this.films;
  }
  getAllItemInCart(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "FetchAllCartItemDynamic?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    // console.log("pall_weeklt_hea", header)
    // console.log("pall_weeklt_CardNo", CardNo)
    // console.log("pall_weeklt_pass", Password)
    return this.films;
  }

  deleteItemFromCart(
    CardNo: string,
    header: string,
    id: string,
    Password: string
  ) {
    this.films = this.http.get(
      this.url +
        "deleteItemFromCart?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&id=" +
        id +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getAllNotification(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "fetchAllNotification?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  updateCartProduct(
    CardNo: string,
    header: string,
    cartid: string,
    quantity: number,
    pack: string,
    Password: string
  ) {
    this.films = this.http.get(
      this.url +
        "updateCartProduct?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&cartid=" +
        cartid +
        "&quantity=" +
        quantity +
        "&pack=" +
        pack +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getKgQuantity() {
    this.films = this.http.get(this.url + "getKgQty");
    return this.films;
  }

  getAllOrderHistory(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "GetOrderHistoryDynamic?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getPlaceOrder(CardNo: string, header: string, Password: string) {
    // CardNo= encodeURIComponent(CardNo);
    this.films = this.http.get(
      this.url +
        "GetPlaceOrderDynamic?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getAllOffers(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "getAllOffers?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );

    return this.films;
  }

  changeIsAlternative(
    CardNo: string,
    header: string,
    Password: string,
    isAlternative: string,
    cartitemid: string
  ) {
    this.films = this.http.get(
      this.url +
        "ChangeIsAlternative?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&isAlternative=" +
        isAlternative +
        "&cartitemid=" +
        cartitemid
    );
    return this.films;
  }

  changeIsAlternativeByCardNo(
    CardNo: string,
    header: string,
    Password: string,
    isAlternative: string
  ) {
    this.films = this.http.get(
      this.url +
        "ChangeIsAlternativeByCardNo?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&isAlternative=" +
        isAlternative
    );
    return this.films;
  }

  addItemInWishList(
    CardNo: string,
    header: string,
    internalkey: string,
    cardWishType: string,
    qty: number,
    packQuantity: string,
    Password: string,
    Price:number
  ) {
    this.films = this.http.get(
      this.url +
        "addItemInCart?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&internalkey=" +
        internalkey +
        "&CartwishType=" +
        cardWishType +
        "&quantity=" +
        qty +
        "&packQuantity=" +
        packQuantity +
        "&Password=" +
        Password +
        "&Price=" +
        Price
    );
  
    return this.films;

  }


  getAllItemInWishList(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "fetchAllWishListItem?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  deleteItemFromWishList(
    CardNo: string,
    header: string,
    id: string,
    Password: string
  ) {
    this.films = this.http.get(
      this.url +
        "deleteItemFromWishList?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&id=" +
        id +
        "&Password=" +
        Password
    );
    return this.films;
  }

  moveItemsToCart(
    CardNo: string,
    header: string,
    Password: string,
    ids: string
  ) {
    this.films = this.http.get(
      this.url +
        "moveItemWishlistToCart?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&ids=" +
        ids +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getHomePageImage(
    CardNo: string,
    header: string,
    Password: string,
    Position: string
  ) {
    this.films = this.http.get(
      this.url +
        "getMainPageImgDynamic?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&Position=" +
        Position
    );
    return this.films;
  }

  getHomePageDynamicData(CardNo: string, header: string, Password: string) {
    ///, ContentType: string
    this.films = this.http.get(
      this.url +
        "DynamicTemplate?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    ); // +"&ContentType="+ContentType);
    return this.films;
  }

  getHomePageSubgroup(
    CardNo: string,
    header: string,
    Password: string,
    SubGroupCategory: string
  ) {
    // console.log("~~SubGroupCategory~~~~" + SubGroupCategory);
    this.films = this.http.get(
      this.url +
        "getSubGroupCategory?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&Category=" +
        SubGroupCategory
    );
    return this.films;
  }

  getSecondPageImage(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "getSecondPageImgDynamic?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getSingleProduct(
    CardNo: string,
    header: string,
    Password: string,
    productkey: string,
   
  ) {
    this.films = this.http.get(
      this.url +
        "getSingleProduct?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&productkey=" +
        productkey 
    );
    return this.films;
  }

  getRegistration(
    firstname: string,
    lastname: string,
    address: string,
    telephone: string,
    mobile: string,
    dob: string,
    email: string,
    strImage: object 
  ) {  
    this.films = this.http.get(
      this.url +
        "getRegistrationFileUpload?Firstname="+
        firstname +
        "&LastName=" +
        lastname +
        "&Address=" +
        address +
        "&Telephone=" +
        telephone +
        "&Mobile=" +
        mobile +
        "&DOB=" +
        dob +
        "&Email=" +
        email +
        "&FileUpload=" +
        strImage 
    );

    return this.films;
  }


  getRegistration1(
    firstname: string,
    lastname: string,
    address: string,
    telephone: string,
    mobile: string,
    dob: string,
    email: string,
    strImage: string 
  ) {  


    const formData = new FormData();

    formData.append('Firstname', firstname);
    formData.append('LastName', lastname);
    formData.append('Address', address);
    formData.append('Telephone', telephone);
    formData.append('Mobile', mobile);
    formData.append('DOB', dob);
    formData.append('Email', email);
    formData.append('FileUpload', strImage);

    this.films = this.http.post(this.url + "getRegistrationFileUpload", formData, {
      headers: {
        // 'content':"application/json", 
        // 'content-type':"application/x-www-form-urlencoded",
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
        // 'Accept': 'application/json',
        // 'content-type': 'application/json',
        // 'Doittoken': "TVRJek5EVWhRQ01r"
      },
    });
    return  this.films;

  }




  SubmitFeedbackWithoutImage(
    subject: string,
    description: string,
    cardno: string,
    header: string
  ) {
    // post method  with header and parameter
    let postData = new FormData();
    postData.append("Subject", subject);
    postData.append("Description", description);
    postData.append("Cardno", cardno);
    postData.append("Header", header);

    this.login = this.http.post(this.url + "submitFeedback", postData, {
      headers: {
        // 'content':"application/json",
        // 'content-type':"application/x-www-form-urlencoded",
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
        // 'Accept': 'application/json',
        // 'content-type': 'application/json',
        // 'Doittoken': "TVRJek5EVWhRQ01r"
      },
    });
    return this.login;
  }

  getGiftCard(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "getGiftCardByCardNo?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }



  registrations(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "getGiftCardByCardNo?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }



  deleteGiftCard(
    CardNo: string,
    header: string,
    Password: string,
    Gitcardid: string
  ) {
    this.films = this.http.get(
      this.url +
        "deleteGiftCard?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&giftcardid=" +
        Gitcardid
    );
    return this.films;
  }

  sendGiftCard(
    CardNo: string,
    header: string,
    Password: string,
    Gitcardid: string,
    ReciverCardNo: string
  ) {
    this.films = this.http.get(
      this.url +
        "sendGiftCard?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        +"&giftcardid=" +
        Gitcardid +
        "&recieverCardno=" +
        ReciverCardNo
    );
    return this.films;
  }

  getTop10UserWishlist(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "Top10UserWishlist?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getTop10UserOrderedlist(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "Top10UserOrderedlist?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getTop10UserProcessedOrderedlist(
    CardNo: string,
    header: string,
    Password: string
  ) {
    this.films = this.http.get(
      this.url +
        "Top10UserProcessedOrderedlist?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getSingleProductByInternalKey(
    cardno: string,
    Header: string,
    Password: string,
    productkey: string,
  ) {
    this.films = this.http.get(
      this.url +
        "GetSingleProductDynamic?cardno=" +
        cardno +
        "&Header=" +
        Header +
        "&Password=" +
        Password +
        "&productkey=" +
        productkey 
    );

    // console.log(
    //   "~~by internal key~~~" +
    //     this.url +
    //     "getSingleProductWithInternalKey?cardno=" +
    //     CardNo +
    //     "&Header=" +
    //     header +
    //     "&Password=" +
    //     Password +
    //     "&productkey=" +
    //     productkey
    // );
    return this.films;
  }

  setWeeeklyOfferHeadingNav(FirstHeadingWeekly: string) {
    this.FirstHeadingWeekly = FirstHeadingWeekly;
  }

  getWeeeklyOfferHeadingNav() {
    return this.FirstHeadingWeekly;
  }

  setStorageCardNo(loginCardNo: string) {
    this.LoginCardNo = loginCardNo;
  }

  getStorageCardNo() {
    // console.log("this.LoginCardNo",this.LoginCardNo)
    return this.LoginCardNo;
  }

  setStoragePassword(loginPassword: string) {
    this.loginPassword = loginPassword;
  }

  getStoragePassword() {
    // console.log("this.LoginPassword ",this.loginPassword )
    return this.loginPassword;
  }

  setStorageHeader(loginheader: string) {
    this.LoginHeader = loginheader;
  }

  getStorageHeader() {
    // console.log("this.LoginHeader",this.LoginHeader)
    return this.LoginHeader;
  }

  setIsGuest(IsGuest: string) {
    this.isGuest = IsGuest;
  }

  getIsGuest() {
    return this.isGuest;
  }

  getCardCount() {
    return this.CartCount;
  }

  setCardCount(cartCount: any) {
    this.CartCount = cartCount;
  }

  setCurrency(currency: string) {
    this.Currency = currency;
  }

  getCurrency() {
    return this.Currency;
  }

  setFirebaseToken(token: string) {
    this.FirebaseToken = token;
  }

  getFirebaseToken() {
    return this.FirebaseToken;
  }

  // ==================================================== / Storage getter and setter=======================================================================

  // ====================================================Web Notification=======================================================================

  init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      navigator.serviceWorker.ready.then(
        (registration) => {
          // Don't crash an error if messaging not supported
          if (!firebase.messaging.isSupported()) {
            resolve();
            return;
          }

          const messaging = firebase.messaging();

          // Register the Service Worker
          messaging.useServiceWorker(registration);

          // Initialize your VAPI key
          messaging.usePublicVapidKey(environment.firebase.vapidKey);

          // Optional and not covered in the article
          // Listen to messages when your app is in the foreground
          messaging.onMessage((payload) => {
            // console.log(payload);
          });
          // Optional and not covered in the article
          // Handle token refresh
          messaging.onTokenRefresh(() => {
            messaging
              .getToken()
              .then((refreshedToken: string) => {
                this.setFirebaseToken(refreshedToken);
                // console.log(refreshedToken);
              })
              .catch((err) => {
                console.error(err);
              });
          });

          resolve();
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  requestPermission(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (!Notification) {
        resolve();
        return;
      }
      if (!firebase.messaging.isSupported()) {
        resolve();
        return;
      }
      try {
        const messaging = firebase.messaging();
        await messaging.requestPermission();

        const token: string = await messaging.getToken();

        this.setFirebaseToken(token);

        // console.log("User notifications token:", token);
      } catch (err) {
        // No notifications granted
      }

      resolve();
    });
  }

  // ==================================================== / Web Notification=======================================================================

  getLoginPostLive(CardNo: string, pass: string) {
    // post method  with header and parameter
    let postData = new FormData();
    postData.append("CardNo", CardNo);
    postData.append("Password", pass);
    postData.append("DeviceId", "25252525");
    postData.append("DeviceType", "android");

    this.login = this.http.post(
      "http://dashboard.doit.aw:8081/doit_copy/user2/login",
      postData,
      {
        headers: {
          // 'content':"application/json",
          // 'content-type':"application/x-www-form-urlencoded",
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
          // 'Accept': 'application/json',
          // 'content-type': 'application/json',
          Doittoken: "TVRJek5EVWhRQ01r",
        },
      }
    );
    return this.login;
  }

  getLoginGet() {
    // get method  with parameter
    // Initialize Params Object
    let params = new HttpParams();
    // Begin assigning parameters
    // Make the API call using the new parameters.
    this.login = this.http.get(
      "http://localhost:57350/WebForm1.aspx?login=hello",
      { params: params }
    );
    return this.login;
  }

  startMyThread() {
    return new Promise((resolve, reject) => {
      //pretend a long-running task
      setTimeout(() => {
        resolve(true);
      }, 10);
    });
  }
}

export class Top10Data {
  public id: number;
  public Code: string;
  public internalkey: string;
  public qty: number;
  public isAlternative: string;
  public Pack: string;
  public cardno: string;
  public Carwishtype: string;
  public StockGroup: string;
  public offer_price: string;
  public SellUnit: string;
  public Image: string;
  public Description: string;
  public subgroup: string;
  public CreationDt: string;
  offer_desc: any;
  price: any;
  IsAlternative: any;
  OnHandQty: any;
}

export class MyDataType {
  public id: number;
  public Groupid: string;
  public subgroup: string;
  public ImageID: string;
  public ImageName: string;
  public Image: string;
}
export class CatDataType {
  public id: number;
  public Group: string;
  public ImageID: string;
  public ImageName: string;
  public Image: string;
}

export class UserInfo {
  public FirstName: number;
  public Group: string;
  public ImageID: string;
  public ImageName: string;
  public Image: string;
}
